<template>
  <div class="pad-home">
    <HeaderBar title="优惠券" :showLeft="true"></HeaderBar>
    <div class="coupon">
      <div class="coupon-tabs">
        <van-tabs v-model="active"
                  title-inactive-color="#999999"
                  title-active-color="#0CAA32"
                  color="#0CAA32"
                  :line-width="30"
                  :line-height="2"
                  swipeable
                  :border="false"
                  @change="change">
          <van-tab v-for="(item,index) in coupons"
                   :title="item.title"
                   :key="index">
            <div class="coupon-list">

              <div class="racconAdd-wrap">
                <div class="coupon-margin">
                  <van-list v-model="loading"
                            :finished="finished"
                            finished-text="已无更多优惠券~"
                            @load="onLoad">
                    <div class="coupon-list-item"
                         v-for="coupon in list"
                         :key="coupon.ID">
                      <div :class="['coupon-discount',{'coupon-discount-canuse':coupon.Effective==1,'coupon-discount-cannotuse':coupon.Effective==0}]">
                        <div class="coupon-discount-data">{{coupon.HDJType==1?`$${(coupon.Maxmoney)}`:`${coupon.Percents}折`}}</div>
                        <div class="coupon-discount-max">{{coupon.HDJType==1?`满$${(coupon.Orderprice)}使用`:`最高抵扣${(coupon.Maxmoney)}`}}</div>
                      </div>
                      <!-- {{coupon}} -->
                      <div :class="['coupon-info',{'coupon-info-canuse':coupon.Effective==1,'coupon-info-cannotuse':coupon.Effective==0}]">
                        <div class="coupon-type">
                          <span :class="['coupon-type-title',{'coupon-type-title-canuse':coupon.Effective==1,'coupon-type-title-cannotuse':coupon.Effective==0}]">{{coupon.TypeInfo==1?`通用`:coupon.TypeInfo==2?'专车':coupon.TypeInfo==3?'代驾':'跑腿'}}券</span>
                          <span :class="['coupon-type-tag',{'coupon-type-tag-canuse':coupon.Effective==1,'coupon-type-tag-cannotuse':coupon.Effective==0}]">{{coupon.Effective==0?'已失效':(coupon.Effective==1&&coupon.TypeInfo==1)?'通用':(coupon.Effective==1&&coupon.TypeInfo==2)?'专车':(coupon.Effective==1&&coupon.TypeInfo==3)?'代驾':(coupon.Effective==1&&coupon.TypeInfo==4)?'跑腿':''}}</span>
                        </div>
                        <div :class="['coupon-date',coupon.Effective==1?'coupon-date-canuse':'coupon-date-cannotuse']">{{coupon.Start.substr(0,10).replace(/-/g,'.')}}-{{coupon.End.substr(0,10).replace(/-/g,'.')}}</div>
                        <div :class="['coupon-info-maxDiscount',coupon.Effective==1?'coupon-info-maxDiscount-canuse':'coupon-info-maxDiscount-cannotuse']">{{coupon.HDJType==1?`满$${(coupon.Orderprice)}使用`:`最高抵扣${coupon.Maxmoney}`}}</div>
                        <div style="color:#999;font-size:12px">获得原因:{{coupon.Name}}</div>
                      </div>
                    </div>
                    <!-- <raccoupon :item="item"
                             :rateNum="rateNum"></raccoupon> -->
                  </van-list>
                </div>
              </div>
              <div class="coupon-tips">
                <!-- <div class="coupon-tips-content"
                       v-if="finished">已无更多优惠券</div> -->
                <div class="coupon-tips-rule"
                     @click="showRule">使用规则</div>
              </div>

            </div>

            <!-- <div class="coupon-expire">
              查看不可用优惠券
            </div> -->
          </van-tab>
        </van-tabs>
      </div>
    </div>

    <van-popup v-model="show"
               :overlay="true"
               class="coupon-rule">
      <div class="coupon-rules">
        <div class="coupon-rules-title">使用规则</div>
        <div class="coupon-rules-list">
          <ul>
            <li>
              <div class="coupon-rules-list-ques">1、什么是优惠券？</div>
              <div class="coupon-rules-list-ans">xxxxxxxxx</div>
            </li>
            <li>
              <div class="coupon-rules-list-ques">2、在什么条件下可以使用优惠券？</div>
              <div class="coupon-rules-list-ans">xxxxxxxxx</div>
            </li>
            <li>
              <div class="coupon-rules-list-ques">3、使用优惠券有哪些注意事项？</div>
              <div class="coupon-rules-list-ans">xxxxxxxxx</div>
            </li>
            <li>
              <div class="coupon-rules-list-ques">4、如何获得优惠券？</div>
              <div class="coupon-rules-list-ans">xxxxxxxxx</div>
            </li>
            <li>
              <div class="coupon-rules-list-ques">5、可能导致优惠券不能使用的原因和解决办法是什么？</div>
              <div class="coupon-rules-list-ans">xxxxxxxxx</div>
            </li>
          </ul>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import HeaderBar from '@/components/headerbar/headerbar'
import { Tab, Tabs, List, Popup } from 'vant';
import { getCouponList } from '@/service/'
import { mapActions, mapState,/* mapGetters*/ } from 'vuex';
import { getStore } from '../../lib/storage';

export default {
  name: 'coupon',
  components: {
    HeaderBar,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [List.name]: List,
    [Popup.name]: Popup,
    // Raccoupon
  },
  data () {
    return {
      coupons: [
        {
          type: '1',
          title: '出行',
          // couponList: [

          // ]
        },
        {
          type: '2',
          title: '代驾',
          // couponList: []
        },
        {
          type: '3',
          title: '跑腿',
          // couponList: []
        },

      ],
      active: 0,
      list: [],
      loading: false,
      finished: false,
      show: false
    }
  },
  computed: {
    ...mapState({
      userCouponList: ({ userVal }) => userVal.userCouponList,
      rateNum: ({ globalVal }) => globalVal.rateNum,
    }),
    // ...mapGetters(['couponList']),
    activeType () {
      return this.active + 1
    },
    umid () {
      return JSON.parse(getStore('userInfo')).ID
    }
  },
  methods: {
    ...mapActions(['getCoupon', 'exchangeRateHandler']),
    change (index, title) {
      console.log(index);
      console.log(title);
      getCouponList(`${this.umid}`, `${this.activeType}`).then(res => {
        this.loading = false;
        this.finished = true;
        if (res.code == 1) {
          this.list = res.data || [];
        }
      })
    },
    onLoad () {
      setTimeout(() => {
        this.loading = true;
        getCouponList(`${this.umid}`, `${this.activeType}`).then(res => {
          this.loading = false;
          this.finished = true;
          if (res.code == 1) {
            this.list = res.data || [];
          }
        })
      }, 500);
    },
    showRule () {
      this.show = true
    },
  },
  mounted () {
    if (!this.rateNum) {
      this.exchangeRateHandler()
    }
    console.log(this.userCouponList)
  }
}
</script>

<style lang="less" scoped>
@import '../../styles/mixin.less';

.pad-home{
  padding-top: 94px;
}
.racconAdd-wrap {
  // height: e('calc(100vh - 100px)');
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    display: none;
  }
}

.coupon {
  &-tabs {
    padding: 0px 0px;
  }

  &-margin {
    margin: 30px auto;
  }

  &-list {
    // margin-top: 46px;
    margin-bottom: 20px;

    &-item {
      display: flex;
      flex: 1;
      align-items: center;
      width: 690px;
      height: 240px;
      margin: 30px auto 30px;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.14);
      border-radius: 20px;
    }
  }

  &-discount {
    // display: flex;
    // align-items: center;
    width: 260px;
    height: 240px;
    padding: 50px 0 48px;
    box-sizing: border-box;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.14);
    border-radius: 20px 0px 0px 20px;
    text-align: center;

    &-canuse {
      color: #fff;
      background: rgba(4, 186, 47, 1);
    }
    &-cannotuse {
      color: rgba(204, 204, 204, 1);
      background: rgba(238, 238, 238, 1);
    }

    &-data {
      margin-bottom: 24px;
      font-size: 60px;
      font-family: 'PingFangSC-Semibold';
      font-weight: 600;
      // color: rgba(255, 255, 255, 1);
      line-height: 84px;
    }

    &-max {
      font-size: 24px;
      font-family: 'PingFangSC-Regular';
      font-weight: 400;
      // color: rgba(255, 255, 255, 1);
      line-height: 34px;
    }
  }

  &-info {
    width: 430px;
    height: 100%;
    padding: 30px 30px 42px 20px;
    box-sizing: border-box;

    &-canuse {
      color: #fff;
      // background: rgba(4, 186, 47, 1);
    }
    &-cannotuse {
      color: rgba(204, 204, 204, 1);
      // background: rgba(238, 238, 238, 1);
    }

    &-maxDiscount {
      font-size: 28px;
      font-family: 'PingFangSC-Regular';
      font-weight: 400;
      color: rgba(153, 153, 153, 1);
      line-height: 40px;

      &-canuse {
        color: rgba(153, 153, 153, 1);
        // background: rgba(4, 186, 47, 1);
      }
      &-cannotuse {
        color: rgba(204, 204, 204, 1);
        // background: rgba(238, 238, 238, 1);
      }
    }
  }

  &-date {
    font-size: 28px;
    font-family: 'PingFangSC-Regular';
    font-weight: 400;
    color: rgba(153, 153, 153, 1);
    line-height: 40px;

    &-canuse {
      color: rgba(153, 153, 153, 1);
      // background: rgba(4, 186, 47, 1);
    }
    &-cannotuse {
      color: rgba(204, 204, 204, 1);
      // background: rgba(238, 238, 238, 1);
    }
  }

  &-type {
    display: flex;
    flex: 1;
    margin-bottom: 18px;
    justify-content: space-between;
    align-items: center;
    &-title {
      font-size: 40px;
      font-family: 'PingFangSC-Medium';
      font-weight: 500;
      color: rgba(34, 34, 34, 1);
      line-height: 66px;

      &-canuse {
        color: rgba(34, 34, 34, 1);
      }
      &-cannotuse {
        color: rgba(204, 204, 204, 1);
      }
    }
    &-tag {
      // width: 60px;
      padding: 3px 10px;
      height: 40px;

      font-size: 24px;
      font-family: 'PingFangSC-Regular';
      font-weight: 400;

      line-height: 40px;
      text-align: center;

      &-canuse {
        background: rgba(12, 170, 50, 0.1);
        color: rgba(12, 170, 50, 1);
      }

      &-cannotuse {
        background: rgba(204, 204, 204, 0.1);
        color: rgba(153, 153, 153, 1);
      }
    }
  }

  &-expire {
    width: 690px;
    height: 76px;
    margin: 0 auto;
    border-radius: 8px;
    border: 2px solid rgba(204, 204, 204, 1);
    font-size: 30px;
    font-family: 'PingFangSC-Medium';
    font-weight: 500;
    color: rgba(153, 153, 153, 1);
    line-height: 76px;
    text-align: center;
  }

  &-tips {
    // margin-bottom: 100px;
    padding: 0 30px;
    text-align: center;
    &-content {
      display: inline-block;
      // margin: 0 auto;

      font-size: 24px;
      font-family: 'PingFangSC-Regular';
      font-weight: 400;
      color: rgba(204, 204, 204, 1);
      line-height: 34px;
    }

    &-rule {
      // display: inline-block;
      float: right;
      font-size: 24px;
      font-family: 'PingFangSC-Regular';
      font-weight: 400;
      color: rgba(12, 170, 50, 1);
      line-height: 34px;
    }
  }

  &-rule {
    border-radius: 20px;
  }
  &-rules {
    width: 690px;
    height: 792px;
    padding: 60px 30px;
    background: rgba(255, 255, 255, 1);
    border-radius: 20px;
    box-sizing: border-box;

    &-title {
      margin-bottom: 30px;
      font-size: 36px;
      font-family: 'PingFangSC-Medium';
      font-weight: 500;
      color: rgba(34, 34, 34, 1);
      line-height: 50px;
      text-align: center;
    }

    &-list {
      font-size: 30px;
      font-family: 'PingFangSC-Regular';
      font-weight: 400;
      color: rgba(51, 51, 51, 1);
      line-height: 42px;

      li {
        margin-bottom: 20px;
      }

      &-ques {
        margin-bottom: 10px;
      }
      &-ans {
        font-size: 30px;
        font-family: 'PingFangSC-Regular';
        font-weight: 400;
        color: rgba(153, 153, 153, 1);
        line-height: 42px;
      }
    }
  }
}
</style>
